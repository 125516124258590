<script>
import axios from '@/lib/axios';

export default {
  name: 'Utils',
  data() {
    return {
      isDarkMode: JSON.parse(localStorage.getItem('darkMode')),
      uploadUrl: `${window.location.origin}/api/file-upload`,
      uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
      env: {
        urlWithPrefix: `${window.location.origin}/api`,
      },
    };
  },
  methods: {
    toastErrors(error) {
      console.log(error.response);
      this.requestDone();
      const data = error.response.data;

      toastr.error(data.message, 'error');
      if (data.hasOwnProperty('errors')) {
        for (const [key, value] of Object.entries(data.errors)) {
          toastr.error(`${key} : ${value}`);
        }
      }
      errors.forEach((e) => toastr.error(e, 'Failed'));
    },
    getCurrency() {
      return JSON.parse(localStorage.getItem('general_setting')).currency;
    },
    format_number(number) {
      return `${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${this.getCurrency()}`;
    },
    deleteModalNoForm(e, vm, cxt, tableId) {
      e.preventDefault();
      const confirmationMessage = $(cxt).data('message') === undefined
        ? 'Once deleted, you will not be able to recover again !' : $(cxt).data('message');

      Swal.fire({
        title: 'Are you sure?',
        text: confirmationMessage,
        icon: 'warning',
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        confirmButtonColor: '#d33',
      }).then((willDelete) => {
        if (!willDelete.isConfirmed) {
          return false;
        }
        const route = e.target.getAttribute('data-route') || e.target.parentElement.getAttribute('data-route');

        vm.requestStart();
        axios.delete(route).then(() => {
          Swal.fire('Deleted!', 'Successfully', 'success');
          $(`#${tableId}`).dataTable().api().ajax.reload();
          vm.requestDone();
        }).catch(({ response: { data } }) => {
          if ('message' in data) {
            if ('errors' in data) {
              Swal.fire('Deletion', Object.values(data.errors).join(
                '<br/>',
              ), 'error');
            } else {
              Swal.fire('Deletion', data.message, 'error');
            }
          } else {
            Swal.fire('Deletion', 'Failed', 'error');
          }
          vm.requestDone();
        });
      });
    },
    saveColorMode() {
      localStorage.setItem('darkMode', JSON.stringify(this.isDarkMode));
    },
    toggleDarkModeBody(on) {
      if (on) {
        $('body').addClass('dark-mode');
        $('.main-sidebar').removeClass('sidebar-light-primary');
        $('.main-sidebar').addClass('sidebar-dark-primary');
        $('.brand-link').removeClass('navbar-white');
        $('.brand-link').addClass('sidebar-dark');
      } else {
        $('.main-sidebar').removeClass('sidebar-dark-primary');
        $('.main-sidebar').addClass('sidebar-light-primary');
        $('.brand-link').removeClass('sidebar-dark');
        $('.brand-link').addClass('navbar-white');
        $('body').removeClass('dark-mode');
      }
    },
    toggleDarkModeNavbar(on) {
      if (on) {
        $('#navbar').removeClass('navbar-white navbar-light');
        $('#navbar').addClass('navbar-dark');
      } else {
        $('#navbar').removeClass('navbar-dark');
        $('#navbar').addClass('navbar-white navbar-light');
      }
    },
    toggleDarkModeSidebar(on) {
      if (on) {
        $('#sidebar').removeClass('sidebar-light-primary');
        $('#sidebar').addClass('sidebar-dark-primary');
      } else {
        $('#sidebar').addClass('sidebar-light-primary');
        $('#sidebar').removeClass('sidebar-dark-primary');
      }
    },
    setColorModeOnLoad() {
      if (!this.isDarkMode) {
        this.isDarkMode = false;
      } else {
        this.toggleDarkModeBody(true);
        this.toggleDarkModeNavbar(true);
        this.toggleDarkModeSidebar(true);
        $('#darkModeIcon').removeClass('fa-sun');
        $('#darkModeIcon').addClass('fa-moon');
      }

      //  set Listeners
      $('#darkModeButton').click(() => {
        this.isDarkMode = !this.isDarkMode;
        if (this.isDarkMode) {
          this.toggleDarkModeBody(true);
          this.toggleDarkModeNavbar(true);
          this.toggleDarkModeSidebar(true);
          $('#darkModeIcon').removeClass('fa-sun');
          $('#darkModeIcon').addClass('fa-moon');
        } else {
          this.toggleDarkModeBody(false);
          this.toggleDarkModeNavbar(false);
          this.toggleDarkModeSidebar(false);
          $('#darkModeIcon').removeClass('fa-moon');
          $('#darkModeIcon').addClass('fa-sun');
        }
        this.saveColorMode();
      });
    },
    requestDone() {
      const loader = $('#requestSpinner');

      if (loader) loader.css('visibility', 'hidden');
    },
    requestStart() {
      const loader = $('#requestSpinner');

      if (loader) loader.css('visibility', 'visible');
    },
    removeLoader() {
      this.setColorModeOnLoad();
      const loader = $('#loader');

      if (loader) {
        loader.css('height', 0);
        setTimeout(() => {
          loader.children().hide();
        }, 200);
      }
    },
    setWithExpiry(key, value, ttl) {
      localStorage.setItem(key, value);
      const now = new Date();

      // `item` is an object which contains the original value
      // as well as the time when it's supposed to expire
      const item = {
        value,
        expiry: now.getTime() + ttl,
      };

      localStorage.setItem(key, JSON.stringify(item));
    },
    clearLocalStorage() {
      localStorage.removeItem('token');
      localStorage.removeItem('email');
      localStorage.removeItem('user');
      localStorage.removeItem('school');
      localStorage.removeItem('plain_pass');
      localStorage.removeItem('general_setting');
      localStorage.removeItem('permissions');
    },
    fillLocalStorage(data) {
      localStorage.setItem('token', data.token);
      localStorage.setItem('email', data.user.email);
      localStorage.setItem('user', JSON.stringify(data.user));
      if (data.user_type !== 'master') {
        localStorage.setItem('school', JSON.stringify(data.school));
        localStorage.setItem('plain_pass', data.user.plain_pass);
        localStorage.setItem('general_setting', JSON.stringify(data.general_setting));
        localStorage.setItem('permissions', JSON.stringify(data.permissions));
      }
    },
    getPermissionsCode(modName) {
      const permissions = JSON.parse(localStorage.getItem('permissions'));
      const currentModule = permissions.find((permission) => permission.module.title === modName);

      return String(currentModule.permissions);
    },
    displayError(error, action, ctx) {
      if ('message' in error) {
        if ('errors' in error.response.data) {
          Swal.fire(
            action,
            Object.values(error.response.data.errors).join(' <br/>'),
            'error',
          );
        } else {
          Swal.fire(action, error.response.data.message, 'error');
        }
      } else {
        Swal.fire(action, `${ctx.$t('failed')}`, 'error');
      }
    },
    getUserEmail() {
      return localStorage.getItem('email', data.user.email);
    },
  },
};
</script>
