import Vue from 'vue';
import VueRouter from 'vue-router';
import auth from '@/middlewares/auth';
import localization from './middlewares/i18n';
import fileName from './middlewares/fileName';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: {
      requiresLogout: true,
    },
    component: () => import('@/views/Auth/Login2.vue'),
  },
  {
    path: '/',
    meta: {
      requiresStaffAuth: true,
    },
    component: () => import('./views/Main.vue'),
    children: [
      {
        path: '',
        name: 'dashboard',
        component: () => import('./views/Dashboard.vue'),
      },
      {
        path: 'admin-section',
        component: () => import('./views/Parent.vue'),
        children: [
          {
            path: 'admin-setup',
            name: 'adminSetup',
            component: () => import('./views/AdminSection/AdminSetup/List.vue'),
          },
          {
            path: 'complaints',
            component: () => import('./views/Parent.vue'),
            children: [
              {
                path: '/',
                name: 'listComplaints',
                component: () => import('./views/AdminSection/Complaints/List.vue'),
              },
              {
                path: 'add',
                name: 'addComplaint',
                component: () => import('./views/AdminSection/Complaints/View.vue'),
                meta: { action: 'add' },
              },
              {
                path: ':complaintId/edit',
                name: 'editComplaint',
                component: () => import('./views/AdminSection/Complaints/View.vue'),
                meta: { action: 'edit' },
              },
            ],
          },
          {
            path: 'student-certificate',
            component: () => import('./views/Parent.vue'),
            children: [
              {
                path: '/',
                name: 'listStudentCertificates',
                component: () => import('./views/AdminSection/StudentCertificate/List.vue'),
              },
              {
                path: 'add',
                name: 'addStudentCertificate',
                component: () => import('./views/AdminSection/StudentCertificate/View.vue'),
                meta: { action: 'add' },
              },
              {
                path: ':certificateId/edit',
                name: 'editStudentCertificate',
                component: () => import('./views/AdminSection/StudentCertificate/View.vue'),
                meta: { action: 'edit' },
              },
            ],
          },
          {
            path: 'generate-certificate',
            name: 'generateCertificate',
            component: () => import('./views/AdminSection/GenerateCertificate/List.vue'),
          },
          {
            path: 'student-id-card',
            component: () => import('./views/Parent.vue'),
            children: [
              {
                path: '/',
                name: 'listStudentIdCards',
                component: () => import('./views/AdminSection/StudentIdCard/List.vue'),
              },
              {
                path: 'add',
                name: 'addStudentIdCard',
                component: () => import('./views/AdminSection/StudentIdCard/View.vue'),
                meta: { action: 'add' },
              },
              {
                path: ':cardId/edit',
                name: 'editStudentIdCard',
                component: () => import('./views/AdminSection/StudentIdCard/View.vue'),
                meta: { action: 'edit' },
              },
            ],
          },
        ],
      },
      {
        path: 'academics',
        component: () => import('./views/Parent.vue'),
        children: [
          {
            path: 'classes',
            component: () => import('./views/Parent.vue'),
            children: [
              {
                path: '/',
                name: 'listClasses',
                component: () => import('./views/Academics/Classes/List.vue'),
              },
              {
                path: 'add',
                name: 'addClass',
                component: () => import('./views/Academics/Classes/View.vue'),
                meta: { action: 'add' },
              },
              {
                path: ':classId/edit',
                name: 'editClass',
                component: () => import('./views/Academics/Classes/View.vue'),
                meta: { action: 'edit' },
              },
            ],
          },
          {
            path: 'subjects',
            component: () => import('./views/Parent.vue'),
            children: [
              {
                path: '/',
                name: 'listSubjects',
                component: () => import('./views/Academics/Subjects/List.vue'),
              },
              {
                path: 'add',
                name: 'addSubject',
                component: () => import('./views/Academics/Subjects/View.vue'),
                meta: { action: 'add' },
              },
              {
                path: ':subjectId/edit',
                name: 'editSubject',
                component: () => import('./views/Academics/Subjects/View.vue'),
                meta: { action: 'edit' },
              },
            ],
          },
          {
            path: 'class-room',
            name: 'lisClassRooms',
            component: () => import('./views/Academics/ClassRooms/List.vue'),
          },
          {
            path: 'class-template',
            name: 'listClassTemplates',
            component: () => import('./views/Academics/ClassTemplate/List.vue'),
          },
        ],
      },
      {
        path: 'student-information',
        component: () => import('./views/Parent.vue'),
        children: [
          {
            path: 'social-status',
            name: 'socialStatus',
            component: () => import('./views/StudentInformation/SocialStatus/List.vue'),
          },
          {
            path: 'student-attendance',
            name: 'studentAttendace',
            component: () => import('./views/StudentInformation/StudentAttendance/List.vue'),
          },
          {
            path: 'parents',
            component: () => import('./views/Parent.vue'),
            children: [
              {
                path: '/',
                name: 'parentsList',
                component: () => import('./views/StudentInformation/Parents/List.vue'),
              },
              {
                path: 'create',
                name: 'parentsCreate',
                component: () => import('./views/StudentInformation/Parents/View.vue'),
                meta: { action: 'add' },
              },
              {
                path: ':parentId/edit',
                name: 'parentsEdit',
                component: () => import('./views/StudentInformation/Parents/View.vue'),
                meta: { action: 'edit' },
              },
            ],
          },
        ],
      },
      {
        path: 'accounts',
        component: () => import('./views/Parent.vue'),
        children: [
          {
            path: 'chart-of-accounts',
            name: 'chartOfAccounts',
            component: () => import('./views/Accounts/ChartsOfAccounts/List.vue'),
          },
          {
            path: 'incomes',
            component: () => import('./views/Parent.vue'),
            children: [
              {
                path: '/',
                name: 'incomesList',
                component: () => import('./views/Accounts/Incomes/List.vue'),
              },
              {
                path: 'create',
                name: 'addIncome',
                component: () => import('./views/Accounts/Incomes/View.vue'),
                meta: { action: 'add' },
              },
              {
                path: 'edit/:incomeId',
                name: 'editIncome',
                component: () => import('./views/Accounts/Incomes/View.vue'),
                meta: { action: 'edit' },
              },
            ],
          },
          {
            path: 'expenses',
            component: () => import('./views/Parent.vue'),
            children: [
              {
                path: '/',
                name: 'expensesList',
                component: () => import('./views/Accounts/Expenses/List.vue'),
              },
              {
                path: 'create',
                name: 'addExpense',
                component: () => import('./views/Accounts/Expenses/View.vue'),
                meta: { action: 'add' },
              },
              {
                path: 'edit/:expenseId',
                name: 'editExpense',
                component: () => import('./views/Accounts/Expenses/View.vue'),
                meta: { action: 'edit' },
              },
            ],
          },
          {
            path: 'item-category',
            name: 'ListItemCategory',
            component: () => import('./views/Accounts/ItemCategory/List.vue'),

          },
          {
            path: 'item-list',
            name: 'listItemList',
            component: () => import('./views/Accounts/Items/List.vue'),
          },
          {
            path: 'item-stores',
            name: 'itemStores',
            component: () => import('./views/Accounts/ItemsStores/List.vue'),
          },
          {
            path: 'suppliers',
            component: () => import('./views/Parent.vue'),
            children: [
              {
                path: '/',
                name: 'listSuppliers',
                component: () => import('./views/Accounts/Suppliers/List.vue'),
              },
              {
                path: 'create',
                name: 'addSupplier',
                component: () => import('./views/Accounts/Suppliers/View.vue'),
                meta: { action: 'add' },
              },
              {
                path: 'edit/:supplierId',
                name: 'editSupplier',
                component: () => import('./views/Accounts/Suppliers/View.vue'),
                meta: { action: 'edit' },
              },
            ],
          },
          {
            path: 'item-receive',
            component: () => import('./views/Parent.vue'),
            children: [
              {
                path: '/',
                name: 'listReceives',
                component: () => import('./views/Accounts/ItemsReceive/List.vue'),
              },
              {
                path: 'create',
                name: 'addReceive',
                component: () => import('./views/Accounts/ItemsReceive/View.vue'),
                meta: { action: 'add' },
              },
              {
                path: 'edit/:receiveId',
                name: 'editReceive',
                component: () => import('./views/Accounts/ItemsReceive/View.vue'),
                meta: { action: 'edit' },
              },
            ],
          },
          {
            path: 'item-sell',
            component: () => import('./views/Parent.vue'),
            children: [
              {
                path: '/',
                name: 'listItemSell',
                component: () => import('./views/Accounts/ItemsSell/List.vue'),
              },
              {
                path: 'create',
                name: 'addItemSell',
                component: () => import('./views/Accounts/ItemsSell/View.vue'),
                meta: { action: 'add' },
              },
              {
                path: 'edit/:sellId',
                name: 'editItemSell',
                component: () => import('./views/Accounts/ItemsSell/View.vue'),
                meta: { action: 'edit' },
              },
            ],
          },
          {
            path: 'item-issue',
            name: 'itemIssue',
            component: () => import('./views/Accounts/Issues/List.vue'),
          },
        ],
      },
      {
        path: 'human-resources',
        component: () => import('./views/Parent.vue'),
        children: [
          {
            path: 'designations',
            name: 'designations',
            component: () => import('./views/HumanResources/Designations/List.vue'),
          },
          {
            path: 'departments',
            name: 'departments',
            component: () => import('./views/HumanResources/Departments/List.vue'),
          },
          {
            path: 'staff-attendance',
            name: 'staffAttendance',
            component: () => import('./views/HumanResources/StaffAttendance/List.vue'),
          },
          {
            path: 'payroll-money-type',
            name: 'payrollMoneyType',
            component: () => import('./views/HumanResources/PayrollMoneyTypes/List.vue'),
          },
          {
            path: 'payroll-template',
            component: () => import('./views/Parent.vue'),
            children: [
              {
                path: '/',
                name: 'listPayrollTemplate',
                component: () => import('./views/HumanResources/PayrollTemplate/List.vue'),
              },
              {
                path: 'add',
                name: 'addPayrollTemplate',
                component: () => import('./views/HumanResources/PayrollTemplate/View.vue'),
                meta: { action: 'add' },
              },
              {
                path: ':id/edit',
                name: 'editPayrollTemplate',
                component: () => import('./views/HumanResources/PayrollTemplate/EditView.vue'),
                meta: { action: 'edit' },
              },
            ],
          },
        ],
      },
      {
        path: 'leave',
        component: () => import('./views/Parent.vue'),
        children: [
          {
            path: 'types',
            name: 'types',
            component: () => import('./views/Leave/LeaveTypes/List.vue'),
          },
          {
            path: 'definitions',
            name: 'definitions',
            component: () => import('./views/Leave/LeaveDefinitions/List.vue'),
          },
          {
            path: 'requests',
            name: 'requests',
            component: () => import('./views/Leave/LeaveRequests/List.vue'),
          },

        ],
      },
      {
        path: 'examination',
        component: () => import('./views/Parent.vue'),
        children: [
          {
            path: 'exam-time',
            name: 'examTime',
            component: () => import('./views/Examination/ExaminationTime/List.vue'),
          },
        ],
      },
      {
        path: 'communicate',
        component: () => import('./views/Parent.vue'),
        children: [
          {
            path: 'chat',
            name: 'chat',
            component: () => import('./views/Communicate/Chat/Index.vue'),
          },
          {
            path: 'template',
            name: 'template',
            component: () => import('./views/Communicate/EmailTemplate/List.vue'),
          },
          {
            path: 'notifications',
            name: 'notifications',
            component: () => import('./views/Communicate/NoticeBoard/List.vue'),
          },
          {
            path: 'events',
            component: () => import('./views/Parent.vue'),
            children: [
              {
                path: '/',
                name: 'listEvents',
                component: () => import('./views/Communicate/Events/List.vue'),
              },
              {
                path: 'add',
                name: 'addEvent',
                component: () => import('./views/Communicate/Events/View.vue'),
                meta: { action: 'add' },
              },
              {
                path: ':eventId/edit',
                name: 'editEvent',
                component: () => import('./views/Communicate/Events/View.vue'),
                meta: { action: 'edit' },
              },
            ],
          },
        ],
      },
      {
        path: 'library',
        component: () => import('./views/Parent.vue'),
        children: [
          {
            path: 'book-categories',
            name: 'book-categories',
            component: () => import('./views/Library/BookCategories/List.vue'),
          },
          {
            path: 'subjects',
            name: 'subjects',
            component: () => import('./views/Library/Subjects/List.vue'),
          },
          {
            path: 'books',
            component: () => import('./views/Parent.vue'),
            children: [
              {
                path: '/',
                name: 'listBooks',
                component: () => import('./views/Library/Books/List.vue'),
              },
              {
                path: 'add',
                name: 'addBook',
                component: () => import('./views/Library/Books/View.vue'),
                meta: { action: 'add' },
              },
              {
                path: ':bookId/edit',
                name: 'editBook',
                component: () => import('./views/Library/Books/View.vue'),
                meta: { action: 'edit' },
              },
            ],
          },
          {
            path: 'members',
            name: 'listMembers',
            component: () => import('./views/Library/Members/List.vue'),
          },
        ],
      },
      {
        path: 'transport',
        component: () => import('./views/Parent.vue'),
        children: [
          {
            path: 'assign-vehicle/list',
            name: 'listAssignVehicles',
            component: () => import('./views/Transport/AssignVehicles/List.vue'),
          },
          {
            path: 'vehicle/list',
            name: 'listVehicles',
            component: () => import('./views/Transport/Vehicles/List.vue'),
          },
          {
            path: 'routes/list',
            name: 'listRoutes',
            component: () => import('./views/Transport/Routes/List.vue'),
          },
        ],
      },
      {
        path: 'fees',
        component: () => import('./views/Parent.vue'),
        children: [
          {
            path: 'fees-group',
            name: 'listFeesGroup',
            component: () => import('./views/FeesCollection/FeesGroups/List.vue'),
          },
          {
            path: 'fees-type',
            name: 'listFeesType',
            component: () => import('./views/FeesCollection/FeesTypes/List.vue'),
          },
          {
            path: 'fees-master',
            name: 'listFeesMaster',
            component: () => import('./views/FeesCollection/FeesMaster/List.vue'),
          },
          {
            path: 'invoices',
            name: 'listInvoices',
            component: () => import('./views/FeesCollection/Invoices/List.vue'),
          },
          {
            path: 'payments-history',
            name: 'listPaymentHistory',
            component: () => import('./views/FeesCollection/PaymentHistory/List.vue'),
          },
        ],
      },
      {
        path: '/reports',
        component: () => import('./views/Parent.vue'),
        children: [
          {
            path: 'transaction-report',
            name: 'transactionReport',
            component: () => import('./views/Reports/Transaction/List.vue'),
          },
          {
            path: 'paid-fees-report',
            name: 'paidFeesReport',
            component: () => import('./views/Reports/PaidFees/List.vue'),
          },
          {
            path: 'due-fees-report',
            name: 'dueFeesReport',
            component: () => import('./views/Reports/DueFees/List.vue'),
          },
          {
            path: 'students-report',
            name: 'studentsReport',
            component: () => import('./views/Reports/Students/List.vue'),
          },
          {
            path: 'class-report',
            name: 'classReport',
            component: () => import('./views/Reports/Class/List.vue'),
          },
          {
            path: 'class-section-report',
            name: 'classSectionReport',
            component: () => import('./views/Reports/ClassSection/List.vue'),
          },
          {
            path: 'autogenerate-reports',
            name: 'autoGenerateReport',
            component: () => import('./views/Reports/AutoGenerated/List.vue'),
          },
        ],
      },
      {
        path: 'system-settings',
        component: () => import('./views/Parent.vue'),
        children: [
          {
            path: 'update-general-settings',
            name: 'update-general-settings',
            component: () => import('./views/SystemSettings/GeneralSettings/List.vue'),
          },
          {
            path: 'payment',
            name: 'payment',
            component: () => import('./views/SystemSettings/Payment/List.vue'),
          },
          {
            path: 'mail-settings',
            name: 'mail-settings',
            component: () => import('./views/SystemSettings/MailSetting/List.vue'),
          },
          {
            path: 'roles',
            component: () => import('./views/Parent.vue'),
            children: [
              {
                path: '/',
                name: 'listRoles',
                component: () => import('./views/SystemSettings/Roles/List.vue'),
              },
              {
                path: ':rolesId',
                name: 'assign-roles',
                component: () => import('./views/SystemSettings/Roles/View.vue'),
                meta: { action: 'edit' },
              },
            ],
          },
          {
            path: 'events_hide_date_range',
            name: 'events_hide_date_range',
            component: () => import('./views/SystemSettings/ExamsDateRange/List.vue'),
          },
          {
            path: 'academic-years',
            name: 'academic-years',
            component: () => import('./views/SystemSettings/AcademicYear/List.vue'),
          },
          {
            path: 'semester',
            name: 'semester',
            component: () => import('./views/SystemSettings/Semesters/List.vue'),
          },
          {
            path: 'holidays',
            name: 'holidays',
            component: () => import('./views/SystemSettings/Holidays/List.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/cms',
    meta: {
      requiresStaffAuth: true,
    },
    component: () => import('@/views/AdminPanel.vue'),
    children: [
      {
        path: 'schools',
        component: () => import('./views/Parent.vue'),
        children: [
          {
            path: 'list',
            name: 'schoolList',
            component: () => import('./views/cms/schools/List.vue'),
          },
          {
            path: 'create',
            name: 'createSchool',
            component: () => import('./views/cms/schools/View.vue'),
            meta: { action: 'add' },
          },
          {
            path: ':schoolId/edit',
            name: 'editSchool',
            component: () => import('./views/cms/schools/View.vue'),
            meta: { action: 'edit' },
          },
        ],
      },
      {
        path: 'languages',
        component: () => import('./views/Parent.vue'),
        children: [
          {
            path: 'list',
            name: 'languagesList',
            component: () => import('./views/cms/languages/List.vue'),
          },
          {
            path: 'create',
            name: 'createLanguage',
            component: () => import('./views/cms/languages/View.vue'),
            meta: { action: 'add' },
          },
          {
            path: ':languageId/edit',
            name: 'editLanguage',
            component: () => import('./views/cms/languages/View.vue'),
            meta: { action: 'edit' },
          },
        ],
      },
      {
        path: 'language-settings',
        component: () => import('./views/Parent.vue'),
        children: [
          {
            path: 'list',
            name: 'languageSettingsList',
            component: () => import('./views/cms/languageSettings/List.vue'),
          },
          {
            path: ':languageSettingId/edit',
            name: 'editLanguageSetting',
            component: () => import('./views/cms/languageSettings/View.vue'),
            meta: { action: 'edit' },
          },
        ],
      },
    ],
  },
  {
    path: '*',
    name: '404',
    meta: {
      requiresLogout: false,
    },
    component: () => import('@/views/404.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
});

router.beforeEach(auth);

router.beforeEach(localization);

router.afterEach(fileName);

export default router;
