import Vue from 'vue';
import { NlyAdminlteVue, NlyAdminlteVueIcons } from 'nly-adminlte-vue';
import App from './App.vue';
import router from './router';
import store from './store/index';
import Debug from './mixins/Debug.vue';
import Utils from './mixins/Utils.vue';
import { i18n } from './i18n-setup';
import 'vue-select/dist/vue-select.css';
import 'nly-adminlte-vue/dist/adminlte/css/adminlte.css';
import 'nly-adminlte-vue/dist/adminlte/fontawesome-free/css/all.css';
import 'nly-adminlte-vue/dist/adminlte/icon/iconfont.css';
import 'nly-adminlte-vue/dist/nly-adminlte-vue.css';

Vue.use(NlyAdminlteVue);
Vue.use(NlyAdminlteVueIcons);

Vue.config.productionTip = false;

Vue.mixin(Utils);
Vue.mixin(Debug);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
