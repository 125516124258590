<script>
export default {
  name: 'Debug',
  data() {
    return {};
  },
  methods: {
    listFormData(fd) {
      for (const pair of fd.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }
    },
  },
};
</script>
